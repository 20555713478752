import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref, computed } from 'vue'
import type {
  Device,
  ModelColorListParams,
  ModelMemoryListParams,
  ModelColor,
  ModelMemory,
  DeviceListParams,
} from '~/types/models'

export const useCatalogDeviceStore = defineStore('catalog-device', () => {
  const { $api } = useNuxtApp()

  const isLoading = ref<boolean>(false)

  const item = ref<Device | null | undefined>()

  const isNew = computed(() => checkIsNew(item.value))
  const isIPhone = computed(() => checkIsIPhone(item.value))
  const minCreditPrice = computed(() => getMinCreditPrice(item.value))
  const monthlyPayment = computed(() => getMonthlyPayment(item.value))

  const memories = ref<ModelMemory[]>([])
  const colors = ref<ModelColor[]>([])

  const selectedMemory = ref<ModelMemory | null>(null)
  const selectedColor = ref<ModelColor | null>(null)

  const setItem = (newItem: Device) => {
    item.value = newItem
  }

  function getItem(deviceId: string) {
    isLoading.value = true

    return $api.devices
      .getDevice(deviceId)
      .then((data) => {
        item.value = data
      })
      .catch((error: Error) => {
        item.value = null
        throw error
      })
      .finally(() => isLoading.value = false)
  }

  function getItems(params: DeviceListParams) {
    isLoading.value = true

    return $api.devices
      .getDevices(params)
      .then((response) => {
        return response.data
      })
      .finally(() => (isLoading.value = false))
  }

  function getColor(params: ModelColorListParams) {
    if (!item.value) return

    return $api.modelColors.getModelColors(params).then((data) => {
      colors.value = data
      return data.find(color => color.id === item.value?.Color.Id)
    })
  }

  function getMemory(params: ModelMemoryListParams) {
    if (!item.value) return

    return $api.modelMemories.getModelMemories(params).then((data) => {
      memories.value = data
      return data.find(memory => memory.id === item.value?.ModelMemory.Id)
    })
  }

  return {
    isLoading,
    item,
    isNew,
    isIPhone,
    minCreditPrice,
    monthlyPayment,
    memories,
    colors,
    selectedMemory,
    selectedColor,
    setItem,
    getItem,
    getItems,
    getColor,
    getMemory,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useCatalogDeviceStore, import.meta.hot),
  )
}